import React from "react"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { useTranslation } from "gatsby-plugin-react-i18next"

import useStyles from "./cookiesBanner.style"
import Link from "./Link"

export default function Quote({ className, quote, onOkClick }) {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="caption" color="inherit">
          {t("cookies.c1")}&nbsp;
          <Link
            classes={{
              root: classes.link,
            }}
            color="inherit"
            href="/privacy-policy"
          >
            {t("cookies.c2")}
          </Link>
          &nbsp;{t("cookies.c3")}&nbsp;
          <Link
            classes={{
              root: classes.link,
            }}
            color="inherit"
            href="/cookies"
          >
            {t("cookies.c4")}
          </Link>
          .
        </Typography>

        <Button
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          variant="contained"
          color="inherit"
          disableRipple
          disableFocusRipple
          onClick={onOkClick}
        >
          {t("cookies.ok")}
        </Button>
      </div>
    </div>
  )
}
