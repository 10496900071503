import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: (props) => props.logoSize || 29,
    height: (props) => props.logoSize || 29,
    marginRight: (props) =>
      props.fullLogo && !isMobile ? theme.spacing(1.5) : undefined,
  },
  name: {
    width: 140,
    height: 32,
  },
}));

export default useStyles;
