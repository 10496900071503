import React, { useState, useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Slide from "@material-ui/core/Slide"

import IconButton from "@material-ui/core/IconButton"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"

import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"

import Logo from "../general/logo"

import NavbarMenu from "./navbarMenu"

import useStyles from "./navbar.style"
import useWamUrl from "../../hooks/useWamUrl"
import clsx from "clsx"

export default ({ transparent, animatedNavbar }) => {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [hideColoredNavbar, setHideColoredNavbar] = useState(animatedNavbar)
  const { redirectToWam } = useWamUrl()
  const { t } = useTranslation()

  const toggleDrawer = () => {
    setDrawerOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (animatedNavbar) {
      document.addEventListener("scroll", () => {
        const isTransparent = window.scrollY < window.innerHeight - 72
        const isOpaque = window.scrollY > window.innerHeight - 72
        if (isTransparent) {
          setHideColoredNavbar(true)
        }
        if (isOpaque) {
          setHideColoredNavbar(false)
        }
      })
    }
  }, [])

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.rootTransparent]: !drawerOpen && transparent,
    [classes.fixedRoot]: transparent,
    [classes.animatedRoot]: animatedNavbar,
    [classes.animatedRootHiding]: hideColoredNavbar,
    [classes.animatedRootShowing]: !hideColoredNavbar,
  })

  return (
    <div key="top">
      <div className={rootClasses}>
        <div className={classes.iconsWrapper}>
          {/* TODO: remove the condition after completion of these features */}
          <>
            <IconButton
              aria-label="menu"
              onClick={toggleDrawer}
              size="medium"
              disableRipple
              color={transparent && !drawerOpen ? "inherit" : "primary"}
            >
              {drawerOpen ? (
                <CloseIcon
                  classes={{ colorPrimary: classes.colorPrimary }}
                  color="inherit"
                />
              ) : (
                <MenuIcon
                  classes={{ colorPrimary: classes.colorPrimary }}
                  color="inherit"
                />
              )}
            </IconButton>
          </>
        </div>
        <div className={classes.logoWrapper}>
          <Logo fullLogo yellow={!transparent || drawerOpen} />
        </div>
        <div className={classes.buttonsWrapper}>
          <Button
            classes={{
              root: `${classes.button} ${classes.buttonText} ${
                transparent && !drawerOpen && classes.buttonTextWhite
              }`,
            }}
            onClick={() => redirectToWam("login")}
            disableElevation
            disableRipple
            disableFocusRipple
            id="login_logout_navbar_button"
          >
            {t("header.signIn")}
          </Button>
          <Button
            classes={{
              root: `${classes.button}`,
            }}
            onClick={() => redirectToWam("join")}
            disableRipple
            disableFocusRipple
            id="signup_navbar_button"
          >
            {t("header.signUp")}
          </Button>
        </div>
      </div>
      <Drawer
        classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}
        ModalProps={{
          BackdropProps: { classes: { root: classes.backdropRoot } },
        }}
        anchor={"top"}
        open={drawerOpen}
        onClose={() => toggleDrawer()}
      >
        <NavbarMenu
          onSignInClick={() => {
            redirectToWam("login")
            toggleDrawer()
          }}
          onSignUpClick={() => {
            redirectToWam()
            toggleDrawer()
          }}
        />
      </Drawer>
    </div>
  )
}
