import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    textTransform: "uppercase",
  },
  link: {
    display: "block",
    padding: theme.spacing(0.5, 0),
    color: "#454F5B",
    textDecoration: "initial",

    "&:hover": {
      textDecoration: "underline",
    },
  },
  footerRow: {
    marginTop: theme.spacing(2),
    boxShadow: "0px -1px 0px #F4F6F8",
    padding: theme.spacing(2, 0),
  },
  rowLink: {
    marginRight: theme.spacing(2),
  },
  rowRight: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  linkColumn: {
    padding: theme.spacing(2),
    minWidth: 260,
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "initial",
    },
  },
}))

export default useStyles
