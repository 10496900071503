import React from "react"

import useStyles from "./storeBadge.style"

import apple_store_badge from "../../images/storeBadges/app-store.svg"
import google_play_badge from "../../images/storeBadges/play-store.svg"

export default function StoreBadge({ store, href, ...props }) {
  const classes = useStyles(props)

  const renderBadge = store => {
    if (store === "apple") {
      return apple_store_badge
    } else if (store === "google") {
      return google_play_badge
    }
  }

  const getHref = () => {
    if (store === "apple") {
      return "https://apps.apple.com/us/app/inshallah-rencontre-musulman/id434630057"
    }
    return "https://play.google.com/store/apps/details?id=com.inchallah.inchmobile&hl=fr&gl=US"
  }

  return (
    <a className={classes.link} href={getHref()}>
      <img
        className={classes.image}
        src={renderBadge(store)}
        alt="store badge"
      />
    </a>
  )
}
