import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,

    "&:disabled": {
      backgroundColor: "#F9FAFB",

      "& $label": {
        color: "#919EAB",
      },
    },
  },
  contained: {
    height: 44,
    minHeight: 44,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    borderRadius: theme.spacing(0.5),
  },
  containedPrimary: {
    color: "#FFF",
    backgroundColor: "#FFC400",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&:focus": {
      backgroundColor: "#FF8B00",
    },
  },
  containedSecondary: {
    color: "#FFC400",
    backgroundColor: "#FFFAE6",

    "&:hover": {
      backgroundColor: "#FFF0B3",
    },

    "&:focus": {
      backgroundColor: "#FFE380",
    },
  },
  text: {
    backgroundColor: "transparent",
    padding: `${theme.spacing(2)}px 0`,

    "& $label": {
      fontWeight: 400,
    },

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  outlined: {
    height: 56,
    minHeight: 56,
    padding: `${theme.spacing(2.25)}px ${theme.spacing(3)}px`,
    border: 0,
    borderBottom: `1px solid #EBEBEB`,
    backgroundColor: "#FFF",
    borderRadius: 0,

    "&:hover": {
      backgroundColor: "#FFF",
      border: 0,
      borderBottom: `1px solid #EBEBEB`,
    },

    "& $label": {
      ...theme.typography.body1,
    },
  },
  outlinedBorderTop: {
    borderTop: `1px solid #EBEBEB`,

    "&:hover": {
      borderTop: `1px solid #EBEBEB`,
    },
  },
  outlinedSecondary: {
    "& $label": {
      color: "#F24235",
    },
  },
  sizeSmall: {
    height: 32,
    minHeight: 32,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
  },
  label: {
    color: "inherit",
    textTransform: "initial",
    ...theme.typography.body2,
    fontWeight: 500,
  },
  preLabel: {
    ...theme.typography.body2,
    color: "#454F5C",
  },
}))

export default useStyles
