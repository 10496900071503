import React from "react"
import { isMobile } from "react-device-detect"

import useStyles from "./logo.style"
import clsx from "clsx"

import app_logo from "../../images/navbar/app-logo.svg"
import app_name from "../../images/navbar/app-name.svg"
import app_logo_yellow from "../../images/navbar/app-logo-yellow.svg"
import app_name_yellow from "../../images/navbar/app-name-yellow.svg"
import app_logo_reversed from "../../images/navbar/app-logo-reversed.svg"

export default function Button({
  yellow,
  reversed,
  alwaysDisplayed,
  ...props
}) {
  const classes = useStyles(props)

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.fullLogoRoot]: props.fullLogo,
  })
  return (
    <div className={rootClasses}>
      <img
        className={classes.logo}
        src={yellow ? app_logo_yellow : reversed ? app_logo_reversed : app_logo}
        alt="app logo"
      />
      {props.fullLogo && (!isMobile || alwaysDisplayed) && (
        <img
          className={classes.name}
          src={yellow ? app_name_yellow : app_name}
          alt="app name"
        />
      )}
    </div>
  )
}
