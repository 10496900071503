import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 2000,
    backgroundColor: theme.palette.common.white,
    height: 76,
    borderBottom: "1px solid #DFE3E8",
    transition: "all 125ms",
  },
  rootTransparent: {
    backgroundColor: "transparent !important",
    borderBottom: "1px solid transparent !important",
    color: "#FFF",
  },
  fixedRoot: {
    position: "absolute",
  },
  animatedRootShowing: {
    transform: "translateY(0px)",
  },
  animatedRootHiding: {
    transform: "translateY(-77px)",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  drawerRoot: {
    top: "76px !important",
    zIndex: "1185px !important",
  },
  drawerPaper: {
    top: "76px !important",
    zIndex: 1185,
  },
  backdropRoot: {
    top: "76px !important",
    zIndex: 1185,
  },
  logoWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 22,

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  buttonsWrapper: {
    position: "relative",
    display: "flex",
    padding: theme.spacing(2, 0),
    marginRight: theme.spacing(3),
    zIndex: 23,
  },
  button: {
    width: 122,
    height: 44,
    background: "linear-gradient(92.81deg, #ffc400 0%, #ffab00 100%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
    ...theme.typography.body2,
    fontWeight: 500,
    textTransform: "initial",
    color: theme.palette.common.white,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonText: {
    color: "#454F5B",
    background: "transparent",
    boxShadow: "none",
  },
  buttonTextWhite: {
    color: "#FFF !important",
  },
  iconsWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(0.5),
    zIndex: 23,

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1.5),
    },
  },
}))

export default useStyles
