import React, { useEffect, useState } from "react"

export default () => {
  const [wamBaseUrl, setWamBaseUrl] = useState()

  const getWamBaseUrl = homepageUrl => {
    const protocol = homepageUrl.split("//")[0]
    const baseUrl = homepageUrl.split("//")[1].split("/")[0].split("?")[0]
    if (homepageUrl.includes("localhost")) {
      return protocol + "//" + baseUrl.replace("8000", "3000") + "/"
    } else {
      if (baseUrl.includes("www")) {
        return `${protocol}//${baseUrl.replace("www", "app")}/`
      } else if (baseUrl === "inshallah.com") {
        return `${protocol}//${baseUrl.replace("inshallah.com", "app.inshallah.com")}/`
      } else {
        return `${protocol}//app-${baseUrl}/`
      }
    }
  }

  useEffect(() => {
    const url = typeof window !== "undefined" ? window.location.href : ""

    setWamBaseUrl(getWamBaseUrl(url))
  }, [])

  const redirectToWam = path => {
    window.open(wamBaseUrl + (typeof path === "string" ? path : ""), "_self")
  }

  return { wamBaseUrl, redirectToWam }
}
