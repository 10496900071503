import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1500,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    color: "#454F5B",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 1200,
    margin: "0 auto",

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  link: {
    textDecoration: "underline",

    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonRoot: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 44,
    borderRadius: theme.spacing(1),
    backgroundColor: "#FFC400",
    boxShadow: "initial",

    "&:hover": {
      backgroundColor: "#FFC400",
      boxShadow: "initial",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginLeft: theme.spacing(4),
      width: 76,
    },
  },
  buttonLabel: {
    color: "#FFF",
    ...theme.typography.body2,
  },
}))

export default useStyles
