import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// import { useStaticQuery, graphql } from "gatsby"

import CssBaseline from "@material-ui/core/CssBaseline"

import NavBar from "../navBar/navbar"
import Footer from "./footer"

import CookiesBanner from "../general/cookiesBanner"

import "../../css/typography.css"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Layout = ({ children, animatedNavbar }) => {
  //   const data = useStaticQuery(graphql`
  //     query SiteTitleQuery {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  //   `)
  const [cookiesShown, setCookiesShown] = useState(undefined)

  const onCookiesClick = () => {
    localStorage.setItem("cookiesShown", true)
    setCookiesShown(false)
  }

  useEffect(() => {
    setCookiesShown(!localStorage.getItem("cookiesShown"))
  }, [])

  return (
    <div style={{ backgroundColor: "#FFF" }}>
      <CssBaseline />
      <header>
        <NavBar transparent />
        <NavBar animatedNavbar={animatedNavbar} />
      </header>

      <main style={{ position: "relative" }}>{children}</main>
      <footer>
        <Footer />
      </footer>
      {cookiesShown && <CookiesBanner onOkClick={onCookiesClick} />}
      {/* <NotificationBanner /> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
