import React from "react"
import { Button as MuiButton } from "@material-ui/core/"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"

import useStyles from "./button.style"

function Button({
  buttonProps,
  className,
  preTitle,
  title,
  onClick,
  isLoading,
  style,
  hasBorderTop,
  buttonId,
}) {
  const classes = useStyles()

  const outlinedClasses = clsx({
    [classes.outlined]: true,
    [classes.outlinedBorderTop]: hasBorderTop,
  })

  return (
    <MuiButton
      id={buttonId}
      {...buttonProps}
      classes={{
        root: `${classes.root} ${className}`,
        contained: classes.contained,
        containedPrimary: classes.containedPrimary,
        containedSecondary: classes.containedSecondary,
        outlined: outlinedClasses,
        outlinedSecondary: classes.outlinedSecondary,
        text: classes.text,
        sizeSmall: classes.sizeSmall,
        label: classes.label,
      }}
      onClick={onClick}
      disableElevation
      disableRipple
      style={style}
    >
      {isLoading ? (
        <CircularProgress color="inherit" size={20} thickness={2} />
      ) : (
        <>
          {preTitle && (
            <Typography className={classes.preLabel} component="span">
              {preTitle}&nbsp;
            </Typography>
          )}
          <span>{title}</span>
        </>
      )}
    </MuiButton>
  )
}

Button.defaultProps = {
  buttonProps: {
    variant: "contained",
    fullWidth: true,
    color: "primary",
    disabled: false,
  },
}

export default Button
