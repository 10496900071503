import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  link: {
    display: "flex",
    margin: props => props.margin || undefined,
  },
  image: {
    objectFit: "cover",
    height: 30,

    [theme.breakpoints.up("md")]: {
      height: 41,
    },
  },
}))

export default useStyles
