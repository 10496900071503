import React, { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql, useStaticQuery } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Link from "../general/Link"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import StoreBadge from "../general/storeBadge"
import Button from "../general/button"

import { useTheme } from "@material-ui/core/styles"
import useStyles from "./navbarMenu.style"
import useWamUrl from "../../hooks/useWamUrl"

export default () => {
  // for now we dont have i18n on categories
  const data = useStaticQuery(graphql`
    query CategoriesQuery {
      allContentfulCategory(filter: { node_locale: { eq: "fr" } }) {
        nodes {
          id
          name
          subcategory {
            name
            seo {
              slug
            }
          }
        }
      }
    }
  `)
  const categories = data.allContentfulCategory.nodes

  const { t } = useTranslation()

  const classes = useStyles()
  const theme = useTheme()

  const { redirectToWam } = useWamUrl()

  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const renderColumnTitle = (title, linkAbove) => {
    return (
      <Link href={linkAbove}>
        <Typography
          className={classes.title}
          variant="body1"
          color="primary"
          style={{ marginTop: linkAbove ? theme.spacing(1.75) : undefined }}
        >
          {title}
        </Typography>
      </Link>
    )
  }

  const renderColumnLink = (title, href) => {
    return (
      <Link className={classes.link} href={href}>
        {title}
      </Link>
    )
  }

  return (
    <>
      <Hidden mdUp>
        <div className={classes.root}>
          {/* <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            square
            elevation={0}
            classes={{
              root: classes.accordionRoot,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{
                root: classes.accordionHeaderRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionExpanded,
              }}
            >
              {renderColumnTitle(t("footer.about"))}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {renderColumnLink(t("footer.mission"))}
              {renderColumnLink("Avis")}
              {renderColumnLink("Blog", "/blog")}
            </AccordionDetails>
          </Accordion> */}
          {/* <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            square
            elevation={0}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              classes={{
                root: classes.accordionHeaderRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionExpanded,
              }}
            >
              <Typography className={classes.accordionTitle} component="body1">
                {renderColumnTitle("L'application")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {renderColumnLink(t("footer.mission"))}
              {renderColumnLink("Avis")}
              {renderColumnLink("Blog", "/blog")}
            </AccordionDetails>
          </Accordion> */}
          {/* <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            square
            elevation={0}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              classes={{
                root: classes.accordionHeaderRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionExpanded,
              }}
            >
              {renderColumnTitle("Entreprise")}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {renderColumnLink(t("footer.mission"))}
              {renderColumnLink("Avis")}
              {renderColumnLink("Blog", "/blog")}
            </AccordionDetails>
          </Accordion> */}
          {/* <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            square
            elevation={0}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              classes={{
                root: classes.accordionHeaderRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionExpanded,
              }}
            >
              {renderColumnTitle(t("footer.social"))}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {renderColumnLink("Instagram")}
              {renderColumnLink("Twitter")}
              {renderColumnLink("Facebook")}
            </AccordionDetails>
          </Accordion> */}
          {categories.map(category => {
            let rootSlug = "/"
            if (category.name === "Blog") {
              rootSlug = "/blog/"
            }
            return (
              <Accordion
                expanded={expanded === category.id}
                onChange={handleChange(category.id)}
                square
                elevation={0}
                classes={{ root: classes.accordionRoot }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel4bh-header"
                  classes={{
                    root: classes.accordionHeaderRoot,
                    content: classes.accordionSummaryContent,
                    expandIcon: classes.accordionExpanded,
                  }}
                >
                  {renderColumnTitle(category.name, rootSlug)}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {category.subcategory.map(subcategory =>
                    renderColumnLink(
                      subcategory.name,
                      rootSlug + subcategory.seo.slug
                    )
                  )}
                </AccordionDetails>
              </Accordion>
            )
          })}
          {/* <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            square
            elevation={0}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel4bh-header"
              classes={{
                root: classes.accordionHeaderRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionExpanded,
              }}
            >
              {renderColumnTitle("Guide musulman")}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {renderColumnLink("Vie de couple")}
              {renderColumnLink("Apparence")}
              {renderColumnLink("Sexualité")}
              {renderColumnLink("Mariage")}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            square
            elevation={0}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel4bh-header"
              classes={{
                root: classes.accordionHeaderRoot,
                content: classes.accordionSummaryContent,
                expandIcon: classes.accordionExpanded,
              }}
            >
              <Typography className={classes.accordionTitle} component="body1">
                {renderColumnTitle(t("footer.legal"))}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {renderColumnLink("Conditions d’utilisation")}
              {renderColumnLink("Propriété intellectuelle")}
              {renderColumnLink("Confidentialité")}
              {renderColumnLink("Cookies 🍪")}
            </AccordionDetails>
          </Accordion> */}
          <div style={{ padding: theme.spacing(2) }}>
            <Button
              title={t("header.signUp")}
              onClick={redirectToWam}
              buttonProps={{
                variant: "contained",
                fullWidth: true,
                color: "primary",
                id: "navbar_menu_signup_button",
              }}
            />
            <Button
              title={t("header.signIn")}
              onClick={() => redirectToWam("login")}
              buttonProps={{
                variant: "contained",
                fullWidth: true,
                color: "secondary",
                id: "navbar_menu_login_logout_button",
              }}
              style={{ marginTop: theme.spacing(2) }}
            />
          </div>
        </div>
      </Hidden>
      <Hidden smDown>
        <div className={classes.root} style={{ padding: "2em" }}>
          <Grid container>
            <Grid item md={3}>
              <div>
                {/* {renderColumnTitle("A propos")}
                {renderColumnLink("Notre mission")}
                {renderColumnLink("Avis")}
                {renderColumnLink("Blog", "/blog")}

                {renderColumnTitle("L'application", true)} */}
                <StoreBadge
                  store="apple"
                  margin={theme.spacing(0.25, 0, 0, 0)}
                />
                <StoreBadge
                  store="google"
                  margin={theme.spacing(0.75, 0, 0, 0)}
                />
              </div>
            </Grid>
            {categories.map(category => {
              let rootSlug = "/"
              if (category.name === "Blog") {
                rootSlug = "/blog/"
              }
              return (
                <Grid item md={3}>
                  {renderColumnTitle(category.name, rootSlug)}
                  {category.subcategory.map(subcategory =>
                    renderColumnLink(
                      subcategory.name,
                      rootSlug + subcategory.seo.slug
                    )
                  )}
                </Grid>
              )
            })}
            {/* <Grid item md={3}>
              {renderColumnTitle("Entreprise")}
              {renderColumnLink("Blog technique")}
              {renderColumnLink("On recrute 👋")}
              {renderColumnLink("Presse")}

              {renderColumnTitle("Nous suivre", true)}
              {renderColumnLink("Instagram")}
              {renderColumnLink("Twitter")}
              {renderColumnLink("Facebook")}
            </Grid>
            <Grid item md={3}>
              {renderColumnTitle("Guide Musulman")}
              {renderColumnLink("Vie de couple")}
              {renderColumnLink("Apparence")}
              {renderColumnLink("Sexualité")}
              {renderColumnLink("Mariage")}
            </Grid>
            <Grid item md={3}>
              {renderColumnTitle("Protection de nos données")}
              {renderColumnLink("Conditions d’utilisation ")}
              {renderColumnLink("Propriété intellectuelle")}
              {renderColumnLink("Confidentialité")}
              {renderColumnLink("Cookies 🍪")}
            </Grid> */}
          </Grid>
        </div>
      </Hidden>
    </>
  )
}
