import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  accordionRoot: {
    "&:before": {
      height: "0px !important",
    },
  },
  accordionHeaderRoot: {
    minHeight: "48px !important",
  },
  accordionExpanded: {
    color: "#212B36",
    padding: 8,
  },
  accordionSummaryContent: {
    margin: "8px 0 !important",
  },
  title: {
    fontFamily: "SFPD",
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    lineHeight: "22px",
    color: "initial",

    [theme.breakpoints.up("md")]: {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      marginBottom: theme.spacing(0.75),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: "18px",
    },
  },
  link: {
    display: "block",
    textAlign: "left",
    ...theme.typography.button,
    textTransform: "initial",
    padding: theme.spacing(1.5, 2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0.25),
    },

    "&:hover": {
      cursor: "pointer",
    },
  },
  accordionDetails: {
    padding: "0 16px",
    flexDirection: "column",
  },
}));

export default useStyles;
