import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql, useStaticQuery } from "gatsby"

import Logo from "../general/logo"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import useStyles from "./footer.style"
import Link from "../general/Link"
function Footer({}) {
  const classes = useStyles()

  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulCategory(
        name: { eq: "Guide musulman" }
        node_locale: { eq: "fr" }
      ) {
        name
        subcategory {
          id
          name
          seo {
            slug
          }
        }
      }
    }
  `)
  const muslimGuides = data.contentfulCategory
    ? data.contentfulCategory.subcategory
    : []

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} container justify="center">
          <Logo yellow />
        </Grid>
        <Box className={classes.linkColumn}>
          <div>
            <Typography
              className={classes.title}
              variant="body1"
              color="primary"
            >
              À propos
            </Typography>
            <Link className={classes.link} color="initial" href="/mission">
              <Typography variant="button" color="initial">
                Notre mission
              </Typography>
            </Link>
            <Link href="/blog" className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Blog
              </Typography>
            </Link>
            {/* <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Blog technique
              </Typography>
            </Link> */}
            <a
              className={classes.link}
              color="initial"
              target="_blank"
              href="https://inshallah.welcomekit.co/jobs/candidatures-spontanees"
            >
              <Typography variant="button" color="initial">
                {t("footer.hiring")}
              </Typography>
            </a>
            {/* <Link className={classes.link} color="initial" href="/presse">
              <Typography variant="button" color="initial">
                Presse
              </Typography>
            </Link> */}
            <a
              className={classes.link}
              color="initial"
              target="_blank"
              href="https://help.inshallah.com"
            >
              <Typography variant="button" color="initial">
                {t("footer.help")}
              </Typography>
            </a>

            <a
              className={classes.link}
              color="initial"
              target="_blank"
              href="https://help.inshallah.com/support/tickets/new"
            >
              <Typography variant="button" color="initial">
                {t("footer.contact")}
              </Typography>
            </a>
          </div>
        </Box>
        {/* <Grid item xs={12} sm={6} md={3}>
          <div>
            <Typography
              className={classes.title}
              variant="body1"
              color="primary"
            >
              Rencontres musulmanes locales
            </Typography>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Rencontres Musulmanes à Paris
              </Typography>
            </Link>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Rencontres Musulmanes à Toulouse
              </Typography>
            </Link>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Rencontres Musulmanes à Lyon
              </Typography>
            </Link>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Rencontres Musulmanes à Marseille
              </Typography>
            </Link>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Rencontres Musulmanes à Nice
              </Typography>
            </Link>
          </div>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <div>
            <Typography
              className={classes.title}
              variant="body1"
              color="primary"
            >
              Rencontres musulmanes par origine
            </Typography>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Site de rencontre Marocain
              </Typography>
            </Link>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Site de rencontre Tunisien
              </Typography>
            </Link>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Site de rencontre Turc
              </Typography>
            </Link>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Site de rencontre Kabyle
              </Typography>
            </Link>
            <Link className={classes.link} color="initial">
              <Typography variant="button" color="initial">
                Rencontres Musulmanes à Nice
              </Typography>
            </Link>
          </div>
        </Grid> */}
        {!!muslimGuides.length && (
          <Box className={classes.linkColumn}>
            <div>
              <Typography
                className={classes.title}
                variant="body1"
                color="primary"
              >
                {t("footer.muslimGuide")}
              </Typography>
              {muslimGuides.map(guide => (
                <Link
                  key={guide.id}
                  className={classes.link}
                  color="initial"
                  href={`/${guide.seo.slug}`}
                >
                  <Typography variant="button" color="initial">
                    {guide.name}
                  </Typography>
                </Link>
              ))}
            </div>
          </Box>
        )}
      </Grid>
      <div className={classes.footerRow}>
        <Grid container spacing={2}>
          <Grid item>
            <Link className={classes.rowLink} color="initial" href="/cookies">
              <Typography variant="button" color="initial">
                Cookies
              </Typography>
            </Link>
            <Link className={classes.rowLink} color="initial" href="/terms">
              <Typography variant="button" color="initial">
                {t("footer.terms")}
              </Typography>
            </Link>
            {/* <Link className={classes.rowLink} color="initial">
              <Typography variant="button" color="initial">
                Mentions légales
              </Typography>
            </Link> */}
            <Link
              className={classes.rowLink}
              color="initial"
              href="/privacy-policy"
            >
              <Typography variant="button" color="initial">
                {t("footer.privacy")}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs>
            <div className={classes.rowRight}>
              <Typography variant="button" color="primary">
                316,997&nbsp;
              </Typography>
              <Typography variant="button" color="initial">
                unions grâce à InshAllah ❤️
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  picture:
    "https://images.unsplash.com/photo-1501785888041-af3ef285b470?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
}

export default Footer
